
.popup-content {
    overflow: unset;
    margin: auto;
    background: rgb(255, 255, 255);
    width: 75%;
    padding: 25px;
    border-radius: 10px;
    max-height: 80vh;
    overflow-y: auto
}

.popup-arrow {
    color: rgb(255, 255, 255);
}

[role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
    background: transparent;
}

.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: 10px;
    top: 10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}