@use '/src/styles/variables';

.helpBtn {
    position: fixed;
    right: 10px;
    bottom: 10px;
    padding: 5px 15px;
    font-family: variables.$font-family-sans-serif;
    font-size: variables.$font-size-sm;
    color: white;
    cursor: pointer;
    background-color: variables.$color-teal;
    border: none;
    border-radius: 10px;
    opacity: 1.0;
}


.helpSection {
    margin: 20px 20px 50px;
}


.helpSection h2 {
    margin-bottom: 20px;
}

.helpSection p, .helpSection li {
    margin-bottom: 10px;
}