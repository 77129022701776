@use '/src/styles/variables';

.container {
    width: 65%;
    margin: 20px auto;
}

.container h2{
    margin-bottom:20px;
}


.drop {
    width: 100%;
    text-align: center;
}

.dropPad {
    padding-top:20px;
}

.icon {
    width: 50px;
    margin:auto;
}

.dropbox {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    clear: both;
    color: rgb(51 51 51);
    background-color: rgb(240 240 240);
    border-color: rgb(153 153 153);
    border-style: dashed;
    border-width: 2px;
    border-radius: 20px;
    outline: none;
}

.dropboxDrag {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    clear: both;
    color: rgb(51 51 51);
    background-color: rgb(240 240 240);
    border-color: variables.$color-teal;
    border-style: dashed;
    border-width: 2px;
    border-radius: 20px;
    outline: none;
}

.dropbox img, .dropboxDrag img {
    opacity: 0.8;
}

.btn {
    width: 30%;
    padding: 5px 15px;
    margin: 5px auto;
    font-weight: bold;
    color: white;
    cursor: pointer;
    background-color: variables.$color-teal;
    border: none;
    border-radius: 10px;
    opacity: 1.0;
}

.btnRow {
    background-color: rgb(240 240 240);
}

.btnDisabled {
    width: 30%;
    padding: 5px 15px;
    margin: 5px;
    font-weight: bold;
    color: white;
    cursor: not-allowed;
    background-color: variables.$color-gray;
    border: none;
    border-radius: 10px;
    opacity: 0.4;
}

.btn:hover {
    opacity: 0.8;
}


.uploadListTableDiv {
    width: 100%;
}

.uploadListTable {
    display: auto;
    width: 100%;
    padding-bottom: 5px;
    margin: 20px 0 0;
    border-color: rgb(153 153 153);
    border-style: solid;
    border-width: 1px 0 0;
}

.uploadListTable tbody tr {
    background-color: white;
}


tr:has(td.feature select) {
    color: orange;
}

tr:has(td.feature select) td.status:after {
    content: '. Warning: Check feature.';
}

.hideUploadListTable {
    display: none;
}

.error {
    color: red;
}

.ready {
    color: limegreen;
}

.actionImg {
    cursor: pointer;
}

.preview {
    width: 110px;
    text-align: center;
}

.filename {
    width: 250px;
}

.dateAdded {
    width: 110px;
}

.action {
    width: 90px;
    text-align: center;
}

.field {
    padding: 0 10px;
}

.progress {
    font-weight: bold;
}

.uploadListTable tbody tr td:first-child,
.uploadListTable tbody tr td:last-child {
    text-align: center;
}
