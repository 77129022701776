@use '/src/styles/variables';

.fileListTable {
  width: 100%;
  padding-bottom: 20px;
  margin: 20px auto;
  border-color: rgb(153 153 153);
  border-style: solid;
  border-width: 2px;
  border-radius: 20px;
}

.fileListTable thead {
  background-color: rgb(240 240 240);
}

.fileListTable thead th {
  padding: 10px;
  text-align: left;
}

.fileListTable thead tr th {
  cursor: default;
}

.fileListTable thead tr th button {
  width: 100%;
  text-align: left;
  border: 0;
}

.fileListTable thead tr th button:hover {
  cursor: pointer;
}

.fileListTable thead tr th button:after {
  float: right;
  color: variables.$color-teal;
}

.fileListTable thead tr th button.ascending:after {
  content: '\2193';
}

.fileListTable thead tr th button.descending:after {
  content: '\2191';
}

.fileListTable tbody td {
  border-bottom: 1px solid rgb(204 204 204);
}

.fileListTable tbody tr:hover {
  background-color: rgb(245 247 247);
}

.fileListTable tbody tr td:first-child,
.fileListTable tbody tr td:last-child {
  text-align: center;
}

.icon {
  width: 50px;
  margin: auto;
}

.error {
  color: red;
}

.ready {
  color: limegreen;
}

.actionImg {
  cursor: pointer;
}

.preview {
  width: 110px;
  text-align: center;
}

.filename {
  width: 250px;
}

.dateAdded {
  width: 300px;
}

.action {
  width: 90px;
  text-align: center;
}

.status {
  width: 200px;
}

.field {
  padding: 0 10px;
}

.hidden {
  display: none;
}

.fileListTable tbody tr td.loading {
  width: 100%;
  padding-top: 15px;
  text-align: center;
  background-color: white;
  border: none;
}
