// Import this file with: @use '/src/styles/variables';
// Reference variables with: variables.$variable-name

// Colors
$color-black: rgb(0 0 0);
$color-gray: rgb(153 152 153);
$color-green: rgb(151 215 0);
$color-purple: rgb(78 0 142);
$color-red: rgb(220 68 5);
$color-teal: rgb(0 191 179);
$color-yellow: rgb(255 198 0);
$color-white: rgb(255 255 255);

// Fonts
$font-family-sans-serif: var(--font-source-code-pro), sans-serif;
$font-family-serif: var(--font-carter-one), serif;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-size-xxs: 1.2rem;
$font-size-xs: 1.4rem;
$font-size-sm: 1.6rem;
$font-size-md: 1.8rem;
$font-size-lg: 2rem;
$font-size-xl: 2.4rem;
$font-size-xxl: 3.2rem;
$font-size-base: $font-size-xs;

// Grid
$gap-xs: 4px;
$gap-sm: 8px;
$gap-md: 16px;
$gap-lg: 32px;
$gap-xl: 64px;
$gap-xxl: 128px;

// Decorations
$box-shadow: 0 5px 10px rgba($color-black, 0.15);
